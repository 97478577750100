export const fetchMetadata = async (url: string, created_at_relative: string) => {
    // Verifica si el enlace es de YouTube
    const isYouTubeUrl = (url: string): boolean => {
        const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
        return youtubeRegex.test(url);
    };

    if (isYouTubeUrl(url)) {
        // Llama al API de YouTube o usa el oEmbed  
        const oEmbedUrl = `https://www.youtube.com/oembed?url=${url}&format=json`;
        const response = await fetch(oEmbedUrl);
        if (!response.ok) throw new Error("Failed to fetch YouTube data");

        const data = await response.json();
        return {
            title: data.title,
            description: data.author_name || "No Description",
            image: data.thumbnail_url,
            created_at_relative,
            siteName: data.provider_name
        };
    } else {
        // Llama a tu proxy de metadatos para otros sitios
        const response = await fetch(`https://us-central1-lastpage-a0b9a.cloudfunctions.net/api/proxy?url=${encodeURIComponent(url)}`);
        const text = await response.text();
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, 'text/html');

        const title = doc.querySelector('title')?.innerText || 'No Title';
        const description = doc.querySelector('meta[name="description"]')?.getAttribute('content') || 'No Description';
        const image = doc.querySelector('meta[property="og:image"]')?.getAttribute('content') || 'https://via.placeholder.com/150';
        const siteName = doc.querySelector('meta[property="og:site_name"]')?.getAttribute('content') || 'No site name';

        return { 
            title, 
            description, 
            image, 
            created_at_relative, 
            siteName 
        };
    }
};